import React, { Component } from 'react'; 
import CSS from './App.css';
import logo from './Assets/Images/logo_beatscr.png';
import lupa from './Assets/Images/Search.png';
var url = require('url');

function fullUrl(req) {
  return url.format({
    protocol: req.protocol,
    host: req.get('host'),
    pathname: req.originalUrl
  });
}



class App extends Component {
  render(){

    const btn_style = {
      height:'25px',
      width:'25px',
      backgroundColor:'white' 
    };
    var fullUrl = req.protocol + '://' + req.get('host') + req.originalUrl;

    return (
      <div className="App">

        A continuación se debería mostrar el Full URL
        {fullUrl}
        <img src={logo}></img>
        <br/>
        Buscar / Search: <br/>

        <input></input>
        
        <button id='btn_search'  onclick='Search();'>
          <img src={lupa} style={btn_style}/>
        </button>



      </div>
    )
  };
}

export default App;
